@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  @page {
    margin: 7mm;
    size: portrait; /* set page size */
  }

  body {
    color: inherit;
  }

  div.tap-menu-page2 {
    page-break-before: always;
  }
}
